<div class="page-content">
  <a class="page-header__back" (click)="goToDocuments()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Documents
  </a>
  <lib-card-title [title]="'Paramétrer le référentiel des documents AAP'"> </lib-card-title>
  <section class="page-section" *ngIf="showForm">
    <!-- Contenu de la page -->
    <div class="form-step" *ngIf="form">
      <div class="mb-4">
        <h4>{{ isModification ? 'Modification du référentiel du document' : "Création d'une nouvelle ligne" }}</h4>
        Saisir tous les champs suivants pour créer votre critère. Les champs avec
        <span class="ds-input-group__label-asterisk"> *</span> sont obligatoire.
      </div>

      <form class="form" [formGroup]="form">
        <div class="d-flex flex-wrap">
          <!-- Section localisation -->

          <div class="ds-input-group w-50 p-2" [class.ds-input-group--error]="!form.controls.scope.valid && form.controls.scope.touched">
            <label for="scope" class="ds-input-group__label">Scope<lib-asterisk></lib-asterisk></label>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="scope" name="scope" formControlName="scope">
                <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
                <option *ngFor="let item of EnumScope.all()" [value]="item">{{ EnumScope.toString(item) }}</option>
              </select>
            </div>
          </div>

          <lib-radio-buttons
            class="w-50 p-2"
            [text]="'Disponibilité des documents sur l\'AAP'"
            [isMandatory]="true"
            [group]="form"
            [options]="OPTIONS_YES_NO"
            controlName="disponibiliteAap"
          >
          </lib-radio-buttons>

          <!-- Fin section localisation -->

          <!-- Section Dépôt -->

          <div
            class="ds-select ds-select-group w-50 p-2"
            [class.ds-select--erreur]="!form.controls.etapes.valid && form.controls.etapes.touched"
          >
            <label for="scope" class="ds-input-group__label">Phase<lib-asterisk></lib-asterisk></label>
            <div class="single-filter-container">
              <lib-ds-multiselect
                class="filter"
                #etapeSelector
                [dataList]="etapeDataList"
                [selectOptions]="{ placeholder: 'Sélectionner' }"
                [name]="'etape_list'"
                (click)="checkEtapes()"
              >
              </lib-ds-multiselect>
            </div>
          </div>

          <div class="ds-input-group w-50 p-2" [class.ds-input-group--error]="!form.controls.nom.valid && form.controls.nom.touched">
            <label for="aide" class="ds-input-group__label">Nom du type de document<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="nom"
                name="nom"
                type="text"
                class="ds-input-group__input"
                formControlName="nom"
                placeholder="Nom du champ du formulaire"
              />
            </span>
          </div>

          <!-- Fin section Dépôt -->

          <!-- Section Niveau d'habilitaion -->

          <div
            class="ds-input-group w-50 p-2"
            [class.ds-input-group--error]="!form.controls.habilitationWKF1.valid && form.controls.habilitationWKF1.touched"
          >
            <label for="scope" class="ds-input-group__label"
              >Niveau d'habilit. WKF1 - Pré-dépôt + dépôt <lib-asterisk></lib-asterisk
            ></label>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="habilitationWKF1" name="habilitationWKF1" formControlName="habilitationWKF1">
                <option [value]="null" selected disabled hidden>Sélectionner</option>
                <option *ngFor="let item of habilitation" value="{{ item.id }}">{{ item.value }}</option>
              </select>
            </div>
          </div>

          <div
            class="ds-input-group w-50 p-2"
            [class.ds-input-group--error]="!form.controls.habilitationWKF2.valid && form.controls.habilitationWKF2.touched"
          >
            <label for="scope" class="ds-input-group__label">Niveau d'habilit WKF2 - Dépôt unique<lib-asterisk></lib-asterisk></label>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="habilitationWKF2" name="habilitationWKF2" formControlName="habilitationWKF2">
                <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
                <option *ngFor="let item of habilitation" value="{{ item.id }}">{{ item.value }}</option>
              </select>
            </div>
          </div>

          <!-- Fin section niveau d'habilitation -->

          <!-- Section Champ obligatoire -->

          <lib-radio-buttons
            class="w-50 p-2"
            [text]="'Document obligatoire'"
            [isMandatory]="true"
            [group]="form"
            [options]="OPTIONS_YES_NO"
            controlName="champsObligatoire"
          >
          </lib-radio-buttons>

          <div
            class="ds-input-group w-50 p-2"
            [class.ds-input-group--error]="!form.controls.codeGed.valid && form.controls.codeGed.touched"
          >
            <label for="codeGed" class="ds-input-group__label">Code GED<lib-asterisk></lib-asterisk></label>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="codeGed" name="codeGed" formControlName="codeGed">
                <option [ngValue]="null" selected disabled hidden>Sélectionner</option>
                <option *ngFor="let item of codeGed" [value]="item">{{ item }}</option>
              </select>
            </div>
          </div>

          <!-- Fin section Champ Obligatoire -->

          <!-- Section structure -->

          <div
            class="ds-select ds-select-group w-50 p-2"
            [class.ds-select--erreur]="!form.controls.typeStructures.valid && form.controls.typeStructures.touched"
          >
            <label for="structure" class="ds-input-group__label"
              >Type de structure(s)<lib-asterisk *ngIf="scope !== EnumScope.PROJET"></lib-asterisk
            ></label>
            <div class="single-filter-container">
              <lib-ds-multiselect
                class="filter"
                #structureSelector
                [dataList]="structureDataList"
                [selectOptions]="{ placeholder: 'Sélectionner', isDisabled: scope === EnumScope.PROJET }"
                [name]="'structure_list'"
                (click)="checkStructure()"
              >
              </lib-ds-multiselect>
            </div>
          </div>

          <div class="ds-input-group w-50 p-2"></div>

          <!-- Fin section structure -->

          <div
            class="ds-select ds-select-group w-50 p-2"
            [class.ds-select--erreur]="!form.controls.typePartenaires.valid && form.controls.typePartenaires.touched"
          >
            <label for="scope" class="ds-input-group__label">Structure projet<lib-asterisk></lib-asterisk></label>
            <div class="single-filter-container">
              <lib-ds-multiselect
                class="filter"
                #structureProjetSelector
                [dataList]="typePartenairesDataList"
                [selectOptions]="{ placeholder: 'Sélectionner' }"
                [name]="'type_partenaires_list'"
                (click)="checkTypePartenaires()"
              >
              </lib-ds-multiselect>
            </div>
          </div>

          <div
            class="ds-select ds-select-group w-50 p-2"
            [class.ds-select--erreur]="!form.controls.roleStructures.valid && form.controls.roleStructures.touched"
          >
            <label for="scope" class="ds-input-group__label"
              >Rôles dans le Projet<lib-asterisk *ngIf="scope !== EnumScope.PROJET"></lib-asterisk
            ></label>
            <div class="single-filter-container">
              <lib-ds-multiselect
                class="filter"
                #roleStructuresSelector
                [dataList]="roleStructuresDataList"
                [selectOptions]="{ placeholder: 'Sélectionner', isDisabled: scope === EnumScope.PROJET }"
                [name]="'role_structures_list'"
                (click)="checkRoleStructures()"
              >
              </lib-ds-multiselect>
            </div>
          </div>

          <div class="w-100 d-flex">
            <ng-container *ngIf="!form.valid && submitClicked">
              <div class="ds-input-group ds-input-group--error w-100">
                <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
              </div>
            </ng-container>

            <div class="w-100 d-flex justify-content-end mt-5">
              <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="goToDocuments()">
                <span>Annuler</span>
              </button>
              <button type="submit" class="ds-btn ds-btn--primary" (click)="saveReferentiel()">
                <span>Enregistrer</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
