<div class="page-content">
  <a class="page-header__back" (click)="goToDepensesProjets()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Dépenses Projets
  </a>
  <lib-card-title [title]="'Paramétrer le référentiel des dépenses projets'"> </lib-card-title>
  <section class="page-section" *ngIf="showForm">
    <!-- Contenu de la page -->
    <div class="form-step" *ngIf="form">
      <div class="mb-4">
        <h4>Edition de "dépenses projets"</h4>
        Saisir tous les champs suivants pour créer votre critère. Les champs avec
        <lib-asterisk></lib-asterisk> sont obligatoire.
      </div>

      <form class="form" [formGroup]="form">
        <span>Taux et Versement</span>
        <div class="d-flex flex-wrap justify-content-between">
          <!-- Taux et Versement -->

          <div
            class="ds-input-group p-2"
            [class.ds-input-group--error]="!form.controls?.pourcentageSolde?.valid && form.controls?.pourcentageSolde?.touched"
          >
            <label for="pourcentageSolde" class="ds-input-group__label">Pourcentage solde (%)<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                libNumericComaDotOnly
                id="pourcentageSolde"
                name="pourcentageSolde"
                type="number"
                class="ds-input-group__input"
                formControlName="pourcentageSolde"
                placeholder="Pourcentage solde (%)"
              />
            </span>
          </div>

          <div class="ds-input-group p-2" [class.ds-input-group--error]="!form.controls?.tauxCE?.valid && form.controls?.tauxCE?.touched">
            <label for="tauxCE" class="ds-input-group__label">Taux CE (%)<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                libNumericComaDotOnly
                id="tauxCE"
                name="tauxCE"
                type="number"
                class="ds-input-group__input"
                formControlName="tauxCE"
                placeholder="Taux CE (%)"
              />
            </span>
          </div>

          <div
            class="ds-input-group p-2"
            [class.ds-input-group--error]="!form.controls?.versementMinimum?.valid && form.controls?.versementMinimum?.touched"
          >
            <label for="versementMinimum" class="ds-input-group__label">Versement Minimum (€)<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                libNumericComaDotOnly
                id="versementMinimum"
                name="versementMinimum"
                type="number"
                class="ds-input-group__input"
                formControlName="versementMinimum"
                placeholder="Versement Minimum (€)"
              />
            </span>
          </div>

          <!-- Fin Taux et Versement -->
        </div>
        <!-- Aide -->
        <span>Aide pour le premier versement (%)</span>
        <div class="d-flex flex-wrap">
          <div
            class="ds-input-group p-2 w-50"
            [class.ds-input-group--error]="!form.controls?.tauxPetiteEntreprise?.valid && form.controls?.tauxPetiteEntreprise?.touched"
          >
            <label for="tauxPetiteEntreprise" class="ds-input-group__label">Petite Entreprise<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                libNumericComaDotOnly
                id="tauxPetiteEntreprise"
                name="tauxPetiteEntreprise"
                type="number"
                class="ds-input-group__input"
                formControlName="tauxPetiteEntreprise"
                placeholder="Petite entreprise"
              />
            </span>
          </div>

          <div
            class="ds-input-group p-2 w-50"
            [class.ds-input-group--error]="!form.controls?.tauxMoyenneEntreprise?.valid && form.controls?.tauxMoyenneEntreprise?.touched"
          >
            <label for="tauxMoyenneEntreprise" class="ds-input-group__label">Moyenne Entreprise<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                libNumericComaDotOnly
                id="tauxMoyenneEntreprise"
                name="tauxMoyenneEntreprise"
                type="number"
                class="ds-input-group__input"
                formControlName="tauxMoyenneEntreprise"
                placeholder="Moyenne entreprise"
              />
            </span>
          </div>

          <div
            class="ds-input-group p-2 w-50"
            [class.ds-input-group--error]="!form.controls?.tauxGrandeEntreprise?.valid && form.controls?.tauxGrandeEntreprise?.touched"
          >
            <label for="tauxGrandeEntreprise" class="ds-input-group__label">Grande Entreprise<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                libNumericComaDotOnly
                id="tauxGrandeEntreprise"
                name="tauxGrandeEntreprise"
                type="number"
                class="ds-input-group__input"
                formControlName="tauxGrandeEntreprise"
                placeholder="Grande entreprise"
              />
            </span>
          </div>

          <div
            class="ds-input-group p-2 w-50"
            [class.ds-input-group--error]="
              !form.controls?.tauxLaboratoireRecherche?.valid && form.controls?.tauxLaboratoireRecherche?.touched
            "
          >
            <label for="tauxLaboratoireRecherche" class="ds-input-group__label">Labo / Non eco<lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                libNumericComaDotOnly
                id="tauxLaboratoireRecherche"
                name="tauxLaboratoireRecherche"
                type="number"
                class="ds-input-group__input"
                formControlName="tauxLaboratoireRecherche"
                placeholder="Labo / Non eco"
              />
            </span>
          </div>

          <!-- Fin Aide -->

          <div class="w-100 d-flex">
            <ng-container *ngIf="!form.valid && submitClicked">
              <div class="ds-input-group ds-input-group--error w-100">
                <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
              </div>
            </ng-container>

            <div class="w-100 d-flex justify-content-end mt-5">
              <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="goToDepensesProjets()">
                <span>Annuler</span>
              </button>
              <button type="submit" class="ds-btn ds-btn--primary" (click)="saveDepensesProjets()">
                <span>Enregistrer</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
